<template>
  <v-container>
    <v-tabs v-model="selectedTab">
      <v-tab v-for="(item, cndex) in items" :key="cndex">
        {{ item.name }}
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="selectedTab">
      <v-tab-item v-for="(item, cndex) in items" :key="'xxx' + cndex">
        <info-tab2
          :item="citem"
          v-for="(citem, index) in item.children"
          :key="'children' + index"
        ></info-tab2>
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import { sync } from "vuex-pathify";
export default {
  components: {
    InfoTab2: () => import("./InfoTab2"),
  },
  data: () => ({
    selectedTab: null,
    hhh: 400,
    items: [
      
    ],
  }),
  computed: {
    ...mapState(["userProfile", "currentUser"]),
     ...sync("*")
  },
  created() {
    this.items.push({
        name: "Xандалтын линкүүд",
        children: [
          {
            name: "Xандалтын линкүүд",
            fbpath: this.userData.ppschool+"/xAccessLinks",
          },
        ],
      },)
  },

  methods: {
    _read() {},
  },
};
</script>
